<template>
  <div>
    <b-container>
      <!-- Select Store -->
      <b-row class="mt-4 answers-wrapper">
        <b-col md="6">
          <div class="form-group">
            <label for="">{{ texts[2] }}</label>
            <select
              v-model="selected_store"
              @change="setStoreCookies()"
              class="form-control"
            >
              <option v-for="store in stores" :key="store.index">
                {{ store.name }}
              </option>
            </select>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import Axios from "axios";
export default {
  name: "SelectStore",
  data() {
    return {
      stores: [],
      selected_store: "",
    };
  },
  mounted() {
    this.getStores();
    this.getTexts();
  },
  computed: {
    texts() {
      return this.$store.state.texts;
    },
  },
  methods: {
    getStores() {
      Axios.get("json/stores.json").then((response) => {
        this.stores = response.data;
      });
    },
    getTexts() {
      this.$store.commit("getTexts");
    },
    setStoreCookies() {
      let store_id = parseInt(
        this.selected_store.substring(0, this.selected_store.length)
      );
      let store_name = this.selected_store.replace(/[0-9\s-]/g, "");
      this.$cookie.set("StoreSelected", "true", 999);
      this.$cookie.set("StoreName", store_name, 999);
      this.$cookie.set("StoreId", store_id, 999);
      this.$router.push("home"); //and redirect to home.
    },
  },
};
</script>
